export const useLocalized = (language) => {
	const targetLanguage = language.split("-")[0];

	const getLocalizedValue = (obj, keyType) => {
		const key = `${keyType}_${targetLanguage}`;
		return obj[key] || "";
	};

	return { getLocalizedValue };
};
