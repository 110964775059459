import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { constants, constants as constant } from "../locales/constant";

/**
 * Fournir la comande ouverte non paye
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const getOrder = createAsyncThunk(
	"cart/getOrder",
	/**
	 * Fournir la comande ouverte non paye
	 * @param _
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (_, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}

			const response = await fetch(`${constant.API_SERVER}/order/`, {
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("fetching orders failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * dupliquer une commande dans le card
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const copyOrder = createAsyncThunk(
	"cart/copyOrder",
	/**
	 * dupliquer une commande dans le card
	 * @param id
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (id, { rejectWithValue, getState, dispatch }) => {
		const {
			auth: { access_token },
		} = getState();

		const header = new Headers();
		if (access_token) {
			header.append("Authorization", "Bearer " + access_token);
		}
		try {
			let response = await fetch(`${constant.API_SERVER}/copyorder/${id}/`, {
				method: "POST",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				response = await fetch(`${constant.API_SERVER}/copyorder/${id}/`, {
					method: "POST",
					credentials: "include",
					headers: header,
				});
			}
			return await response.json();
		} catch (err) {
			try {
				let response = await fetch(`${constant.API_SERVER}/copyorder/${id}/`, {
					method: "POST",
					credentials: "include",
					headers: header,
				});
				return await response.json();
			} catch (_err) {
				return rejectWithValue(_err.message, _err);
			}
		}
	}
);

/**
 * dupliquer une line dans le card
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const copyLine = createAsyncThunk(
	"cart/copyLine",
	/**
	 * dupliquer une commande dans le card
	 * @param id
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (id, { rejectWithValue, getState, dispatch }) => {
		const {
			auth: { access_token },
		} = getState();

		const header = new Headers();
		if (access_token) {
			header.append("Authorization", "Bearer " + access_token);
		}

		try {
			let response = await fetch(`${constant.API_SERVER}/copyline/${id}/`, {
				method: "POST",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				response = await fetch(`${constant.API_SERVER}/copyline/${id}/`, {
					method: "POST",
					credentials: "include",
					headers: header,
				});
			}
			return await response.json();
		} catch (err) {
			try {
				let response = await fetch(`${constant.API_SERVER}/copyline/${id}/`, {
					method: "POST",
					credentials: "include",
					headers: header,
				});
				return await response.json();
			} catch (_err) {
				return rejectWithValue(_err.message, _err);
			}
		}
	}
);

/**
 * mettre a jour une information de la cart
 */
export const updateOrder = createAsyncThunk(
	"cart/updateOrder",
	/**
	 * mettre a jour une information de la cart
	 * @param id
	 * @param order
	 * @param rejectWithValue
	 * @param getState
	 */
	async (order, { rejectWithValue, getState }) => {
		const {
			auth: { access_token },
		} = getState();
		const {
			cart: { order: currentOrder },
		} = getState();

		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");

		try {
			let response = await fetch(`${constants.API_SERVER}/order/${currentOrder.id}/`, {
				method: "PUT",
				credentials: "include",
				headers: header,
				body: JSON.stringify(order),
			});
			if (!response.ok) {
				response = await fetch(`${constants.API_SERVER}/order/${currentOrder.id}/`, {
					method: "PUT",
					credentials: "include",
					headers: header,
					body: JSON.stringify(order),
				});
			}
			return await response.json();
		} catch (err) {
			try {
				let response = await fetch(`${constants.API_SERVER}/order/${currentOrder.id}/`, {
					method: "PUT",
					credentials: "include",
					headers: header,
					body: JSON.stringify(order),
				});
				return await response.json();
			} catch (_err) {
				return rejectWithValue(_err.message, _err);
			}
		}
	}
);

/**
 * rajouter une nouvelle ligne de cart
 */
export const addLine = createAsyncThunk(
	"cart/addLine",
	/**
	 * rajouter une nouvelle ligne de cart
	 * @param variant_id
	 * @param qty
	 * @param qty_pack
	 * @param unitAmount
	 * @param amount
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ variant_id, qty, qty_pack, unitAmount, amount }, { rejectWithValue, getState }) => {
		const {
			auth: { access_token },
		} = getState();

		const {
			cart: { order },
		} = getState();

		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");

		try {
			let response = await fetch(`${constants.API_SERVER}/orderLine/`, {
				method: "POST",
				credentials: "include",
				headers: header,
				body: JSON.stringify({
					order: order ? order.id : "",
					variant: variant_id,
					qty: qty,
					qty_pack: qty_pack,
					unitAmount: unitAmount,
					amount: amount,
				}),
			});
			if (!response.ok) {
				response = await fetch(`${constants.API_SERVER}/orderLine/`, {
					method: "POST",
					credentials: "include",
					headers: header,
					body: JSON.stringify({
						order: order ? order.id : "",
						variant: variant_id,
						qty: qty,
						qty_pack: qty_pack,
						unitAmount: unitAmount,
						amount: amount,
					}),
				});
			}
			return await response.json();
		} catch (err) {
			try {
				let response = await fetch(`${constants.API_SERVER}/orderLine/`, {
					method: "POST",
					credentials: "include",
					headers: header,
					body: JSON.stringify({
						order: order ? order.id : "",
						variant: variant_id,
						qty: qty,
						qty_pack: qty_pack,
						unitAmount: unitAmount,
						amount: amount,
					}),
				});
				return await response.json();
			} catch (_err) {
				return rejectWithValue(_err.message, _err);
			}
		}
	}
);

/**
 * mettre a jour une nouvelle ligne de cart
 */
export const updateLine = createAsyncThunk(
	"cart/updateLine",
	/**
	 * mettre a jour une nouvelle ligne de cart
	 * @param id
	 * @param variant_id
	 * @param qty
	 * @param qty_pack
	 * @param unitAmount
	 * @param amount
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ id, variant_id, qty, qty_pack, unitAmount, amount }, { rejectWithValue, getState }) => {
		const {
			auth: { access_token },
		} = getState();

		const {
			cart: { order },
		} = getState();

		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");

		try {
			let response = await fetch(`${constants.API_SERVER}/orderLine/${id}/`, {
				method: "PUT",
				credentials: "include",
				headers: header,
				body: JSON.stringify({
					order: order.id,
					variant: variant_id,
					qty: qty,
					qty_pack: qty_pack,
					unitAmount: unitAmount,
					amount: amount,
				}),
			});
			if (!response.ok) {
				response = await fetch(`${constants.API_SERVER}/orderLine/${id}/`, {
					method: "PUT",
					credentials: "include",
					headers: header,
					body: JSON.stringify({
						order: order.id,
						variant: variant_id,
						qty: qty,
						qty_pack: qty_pack,
						unitAmount: unitAmount,
						amount: amount,
					}),
				});
			}
			return await response.json();
		} catch (err) {
			try {
				let response = await fetch(`${constants.API_SERVER}/orderLine/${id}/`, {
					method: "PUT",
					credentials: "include",
					headers: header,
					body: JSON.stringify({
						order: order.id,
						variant: variant_id,
						qty: qty,
						qty_pack: qty_pack,
						unitAmount: unitAmount,
						amount: amount,
					}),
				});
				return await response.json();
			} catch (_err) {
				return rejectWithValue(_err.message, _err);
			}
		}
	}
);

/**
 * supprimer une nouvelle ligne de cart
 */
export const deleteLine = createAsyncThunk(
	"cart/deleteLine",
	/**
	 * supprimer une nouvelle ligne de cart
	 * @param id
	 * @param rejectWithValue
	 * @param getState
	 */
	async (id, { rejectWithValue, getState }) => {
		const {
			auth: { access_token },
		} = getState();

		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");

		try {
			let response = await fetch(constants.API_SERVER + `/orderLine/${id}/`, {
				method: "DELETE",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				await fetch(constants.API_SERVER + `/orderLine/${id}/`, {
					method: "DELETE",
					credentials: "include",
					headers: header,
				});
			}
			return id;
		} catch (err) {
			try {
				await fetch(constants.API_SERVER + `/orderLine/${id}/`, {
					method: "DELETE",
					credentials: "include",
					headers: header,
				});
				return id;
			} catch (_err) {
				return rejectWithValue(_err.message, _err);
			}
		}
	}
);

const initialState = {
	currentRequestId: undefined,
	error: undefined,
	loading: "done",
	cartOpen: false,
	dataChanged: false,
	order: undefined,
	displayModal: undefined,
};

const cart = createSlice({
	name: "cart",
	initialState: initialState,
	reducers: {
		setCartOpen(state, action) {
			state.cartOpen = action.payload;
			if (action.payload === false) state.dataChanged = false;
		},
		clear(state) {
			state.order = undefined;
		},
		setDisplayModal(state, action) {
			state.displayModal = action.payload;
		},
	},
	extraReducers: {
		[getOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.order = payload[0];
			}
		},
		[getOrder.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getOrder.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[copyLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.cartOpen = true;
				state.dataChanged = true;
				state.order = payload;
			}
		},
		[copyLine.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[copyLine.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.displayModal = true;
			}
			return state;
		},
		[copyOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.cartOpen = true;
				state.dataChanged = true;
				state.order = payload;
			}
		},
		[copyOrder.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[copyOrder.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.displayModal = true;
			}
			return state;
		},
		[updateOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.order = !payload.orderline ? undefined : payload;
			}
		},
		[updateOrder.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[updateOrder.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.displayModal = true;
			}
			return state;
		},
		[addLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.cartOpen = true;
				state.dataChanged = true;
				if (!state.order) {
					state.order = {
						id: payload.order.id,
						erp_code: payload.order.erp_code,
						order_date: payload.order.order_date,
						delivery_street: payload.order.delivery_street,
						delivery_city: payload.order.delivery_city,
						delivery_postal_code: payload.order.delivery_postal_code,
						delivery_state: payload.order.delivery_state,
						delivery_country: payload.order.delivery_country,
						status: payload.order.status,
						customer: payload.order.customer,
						orderline: [
							{
								id: payload.id,
								status: payload.status,
								qty: payload.qty,
								qty_pack: payload.qty_pack,
								unitAmount: payload.unitAmount,
								discAmount: payload.discAmount,
								discPercent: payload.discPercent,
								amount: payload.amount,
								variant: payload.variant,
								item: payload.item,
								overview_image: payload.overview_image,
							},
						],
					};
				} else {
					const order = state.order;
					order.orderline.push({
						id: payload.id,
						status: payload.status,
						qty: payload.qty,
						qty_pack: payload.qty_pack,
						unitAmount: payload.unitAmount,
						discAmount: payload.discAmount,
						discPercent: payload.discPercent,
						amount: payload.amount,
						variant: payload.variant,
						item: payload.item,
						overview_image: payload.overview_image,
					});
					state.order = order;
				}
			}
		},
		[addLine.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[addLine.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.displayModal = true;
			}
			return state;
		},
		[updateLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.cartOpen = meta.arg.cartOpen;
				state.dataChanged = true;
				const order = state.order;
				const lineIndex = order.orderline.findIndex((l) => l.id === payload.id);
				order.orderline[lineIndex].qty = payload.qty;
				order.orderline[lineIndex].qty_pack = payload.qty_pack;
				order.orderline[lineIndex].unitAmount = payload.unitAmount;
				order.orderline[lineIndex].discAmount = payload.discAmount;
				order.orderline[lineIndex].amount = payload.amount;
				state.order = order;
			}
		},
		[updateLine.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[updateLine.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.displayModal = true;
			}
			return state;
		},
		[deleteLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				const order = state.order;
				order.orderline = order.orderline.filter((l) => l.id !== payload);
				if (!order.orderline || order.orderline.length === 0) {
					state.order = undefined;
				} else {
					state.order = order;
				}
			}
		},
		[deleteLine.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[deleteLine.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.displayModal = true;
			}
			return state;
		},
	},
});

export const { setCartOpen, clear, setDisplayModal } = cart.actions;
export default cart;
export const isLoadingSelector = (state) => state?.cart?.loading === "pending";
export const cartHasErrorSelector = (state) => !!state?.cart?.error && state.cart.error !== "";
export const cartHasItemsSelector = (state) =>
	state?.cart?.order?.orderline && state?.cart?.order?.orderline.length > 0;
export const orderLinesSelector = (state) => state?.cart?.order?.orderline;
export const cartOpenSelector = (state) => state?.cart?.cartOpen;
export const cartDataChangedSelector = (state) => state?.cart?.dataChanged;
export const doDisplayModal = (state) => state?.cart?.displayModal;
export const orderSelector = (state) => state?.cart?.order;
