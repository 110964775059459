import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
	authHasErrorSelector,
	emailSelector,
	forgotPassword,
	isLoadingSelector,
	isLoggedInSelector,
	setPrecedentUrl,
	updateProfile,
} from "../../../store/auth";

import TextInput from "../../ui/Input/TextInput/TextInput";
import Button from "../../ui/Button/Button";

import { keys } from "../../../locales/localeskeys";

import classes from "./Profile.module.scss";
import LinkTree from "../../ui/LinkTree/LinkTree";

const Profile = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoading = useSelector(isLoadingSelector);
	const authHasError = useSelector(authHasErrorSelector);
	const email = useSelector(emailSelector);

	const [editing, setEditing] = useState(false);
	const [requestSent, setRequestSent] = useState(false);
	const [emailState, setEmail] = useState({ value: email, isValid: true, changed: false });

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	const emailChangeHandler = (event) => {
		const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
		setEmail({
			value: event.target.value,
			isValid: event.target.value !== "" && emailRegExp.test(event.target.value.trim()),
			changed: true,
		});
	};

	const submitHandler = (event) => {
		event.preventDefault();

		const user = {};
		user.email = emailState.value;

		if (editing) {
			dispatch(updateProfile(user));
			setEditing(false);
		} else setEditing(true);
	};

	const changePasswordRequest = () => {
		if (!requestSent) {
			dispatch(forgotPassword({ email: emailState.value, lngId: lngId }));
			setRequestSent(true);
		}
	};

	return (
		<div className={classes.bodyContainer}>
			<h1 className={classes.title}>{t(keys.ACCOUNT.PROFILE.TITLE)}</h1>
			<LinkTree
				className={classes.Tree}
				tree={[
					{ label: t(keys.ACCOUNT.ACCOUNT.TITLE), to: "/account/" },
					{ label: t(keys.ACCOUNT.PROFILE.TITLE), to: `/profile?lng=${lngId}` },
				]}
			/>
			<form onSubmit={submitHandler} className={classes.fieldsLayout}>
				<TextInput
					id={"EMAIL"}
					className={classes.input}
					label={t(keys.AUTH.SIGN_UP.EMAIL)}
					placeholder={t(keys.AUTH.SIGN_UP.EMAIL)}
					type="email"
					isValid={emailState.isValid}
					value={emailState.value}
					disabled={!editing}
					onChange={emailChangeHandler}
				/>
				{requestSent ||
					(!requestSent && (
						<p className={classes.description}>
							{t(keys.AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT_Description2)}
						</p>
					))}
				<div className={classes.footer}>
					<p>{authHasError ? t(keys.GLOBAL.COMMON.UNEXPECTED) : ""}</p>
					<div className={classes.buttons}>
						<Button
							size="big"
							color="red"
							disabled={!!isLoading || requestSent}
							onClick={changePasswordRequest}
							className={classes.btn1}
						>
							<div className={classes.content}>
								{requestSent === false
									? t(keys.ACCOUNT.PROFILE.RESETPASSWORD)
									: t(keys.ACCOUNT.PROFILE.REQUESTSENT)}
							</div>
						</Button>
						<Button
							size="big"
							type="submit"
							disabled={!!isLoading || (editing && !emailState.changed) || !emailState.isValid}
							className={classes.btn2}
						>
							<div>{editing === true ? t(keys.ACCOUNT.PROFILE.SAVE) : t(keys.ACCOUNT.PROFILE.EDIT)}</div>
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Profile;
