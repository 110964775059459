import React from "react";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { useTranslation } from "react-i18next";

import useElementSize from "../../../hooks/useElementSize";

import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { constants } from "../../../locales/constant";

import classes from "./Partner.module.scss";
import { keys } from "../../../locales/localeskeys";

const Partner = () => {
	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const [gridRef, { width: gridWidth }] = useElementSize();

	return (
		<div className={classes.container}>
			<h2 className={classes.title}>{t(keys.HOME.PARTNER)}</h2>
			<div ref={gridRef}>
				<CarouselProvider
					className={classes.carousel}
					naturalSlideWidth={100}
					naturalSlideHeight={gridWidth >= 1000 ? 180 : gridWidth >= 800 && gridWidth <= 1000 ? 150 : 145}
					totalSlides={constants.logoUrls.length}
					visibleSlides={gridWidth >= 1200 ? 7 : gridWidth >= 1000 ? 5 : gridWidth >= 800 ? 4 : 2}
					dragEnabled={false}
					isPlaying
					interval={3000}
				>
					<Slider className={classes.slider}>
						{constants.logoUrls.map((item, index) => (
							<Slide innerClassName={classes.slide} index={index} key={index}>
								{item?.certificateUrlFR || item?.certificateUrlEN ? (
									<CustomNavLink
										to={lngId.includes("fr") ? item?.certificateUrlFR : item?.certificateUrlEN}
										target="_blank"
									>
										{lngId.includes("fr") ? (
											<img src={item.logoUrlFR} alt="logo" className={classes.logoImg} />
										) : (
											<img src={item.logoUrlEN} alt="logo" className={classes.logoImg} />
										)}
									</CustomNavLink>
								) : lngId.includes("fr") ? (
									<img src={item.logoUrlFR} alt="logo" className={classes.logoImg} />
								) : (
									<img src={item.logoUrlEN} alt="logo" className={classes.logoImg} />
								)}
							</Slide>
						))}
					</Slider>
				</CarouselProvider>
			</div>
		</div>
	);
};

export default Partner;
