import { configureStore } from "@reduxjs/toolkit";

import auth from "./auth";
import product from "./product";
import productQuery from "./productQuery";
import cart from "./cart";
import variantQuery from "./variantQuery";
import variant from "./variant";
import report from "./report";
import productCategory from "./productCategory";
import segment from "./segment";
import productBrand from "./productBrand";
import subsegment from "./subSegment";
import mediaBase from "./mediaBase";
import architect from "./architect";
import documentCategory from "./documentCategory";
import library from "./library";
import pageLinks from "./pageLinks";
import offices from "./offices";
import faq from "./faq";

export const store = configureStore({
	reducer: {
		auth: auth.reducer,
		product: product.reducer,
		productQuery: productQuery.reducer,
		cart: cart.reducer,
		variant: variant.reducer,
		variantQuery: variantQuery.reducer,
		report: report.reducer,
		productCategory: productCategory.reducer,
		segment: segment.reducer,
		productBrand: productBrand.reducer,
		subsegment: subsegment.reducer,
		mediaBase: mediaBase.reducer,
		architect: architect.reducer,
		documentCategory: documentCategory.reducer,
		library: library.reducer,
		pageLinks: pageLinks.reducer,
		offices: offices.reducer,
		faq: faq.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["offices/getStoreDirection/fulfilled", "offices/getOfficeDirection/fulfilled"],
			},
		}),
});
