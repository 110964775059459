import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import { store } from "./store";

import Loading from "./components/ui/loading/Loading";
import App from "./App";

ReactDOM.render(
	<Suspense fallback={<Loading type={"bounce"} />}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</Suspense>,
	document.getElementById("root")
);
