import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";

import CartItem from "../../../cart/cartItem/CartItem";
import Button from "../../../ui/Button/Button";
import Loading from "../../../ui/loading/Loading";

import {
	isPrintingSelector,
	orderConfirmationSelector,
	orderInvoiceSelector,
	orderPackingSelector,
	printReport,
	reportSelector,
} from "../../../../store/report";
import { taxPercentSelector } from "../../../../store/auth";
import { copyOrder, isLoadingSelector } from "../../../../store/cart";

import { keys } from "../../../../locales/localeskeys";

import classes from "./OrderItem.module.scss";

const OrderItem = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const isPrinting = useSelector(isPrintingSelector);
	const report = useSelector((state) => reportSelector(state, props.id));
	const orderConfigurations = useSelector((state) => orderConfirmationSelector(state, props.id));
	const orderPackings = useSelector((state) => orderPackingSelector(state, props.id));
	const orderInvoices = useSelector((state) => orderInvoiceSelector(state, props.id));
	const taxPercent = useSelector(taxPercentSelector);
	const isCartLoading = useSelector(isLoadingSelector);

	const [total, setTotal] = useState(0);

	useEffect(() => {
		if (report && report.orderline && report.orderline.length > 0) {
			const subAmount = report.orderline
				.map((line) => Number(line.amount))
				.reduce((prev, curr) => prev + curr, 0);
			const taxAmount = (subAmount * taxPercent) / 100;
			const amount = subAmount + taxAmount;
			setTotal(amount);
		}
	}, [report, taxPercent]);

	return (
		<div className={classes.body}>
			{isCartLoading && <Loading type={"bounce"} />}
			{!isCartLoading && (
				<div className={classes.title}>
					<div className={classes.rightFields}>
						<div className={classes.fields}>
							<label>{t(keys.ACCOUNT.ORDERS.SALESORDER)}</label>
							<p>{report.erp_code}</p>
						</div>
						<div className={classes.fields}>
							<label>{t(keys.ACCOUNT.ORDERS.STATUS)}</label>
							<p>{`${
								report.status === 0
									? t(keys.ACCOUNT.ORDERS.OPEN)
									: report.status === 1
									? t(keys.ACCOUNT.ORDERS.CONFIRMED)
									: report.status === 2
									? t(keys.ACCOUNT.ORDERS.DELIVERED)
									: report.status === 3
									? t(keys.ACCOUNT.ORDERS.INVOICED)
									: t(keys.ACCOUNT.ORDERS.CANCELED)
							}`}</p>
						</div>
						<div className={classes.fields}>
							<label>{t(keys.ACCOUNT.ORDERS.PURCHASEORDER)}</label>
							<p>{report?.purchase_order_ref ? report.purchase_order_ref : ""}</p>
						</div>
					</div>
					<div className={classes.leftFields}>
						<div className={classes.fields}>
							<label>{t(keys.ACCOUNT.ORDERS.ORDERDATE)}</label>
							<p>
								{format(new Date(report.order_date), "dd MMM yyyy", {
									locale: lngId.includes("fr") ? fr : en,
								})}
							</p>
						</div>
						<div className={classes.fields}>
							<label>{t(keys.ACCOUNT.ORDERS.TOTAL)}</label>
							<p>
								{lngId.includes("fr")
									? `${total.toFixed(2).replace(".", ",")}$`
									: `$${total.toFixed(2)}`}
							</p>
						</div>
					</div>
				</div>
			)}
			{!isCartLoading && (
				<div>
					<div className={classes.details}>
						{report.orderline && (
							<div className={classes.orderLines}>
								{report.orderline
									.filter((line) => line.item && line.variant)
									.map((line, index) => (
										<div className={classes.cartItem} key={line.id}>
											<CartItem
												key={line.id}
												id={line.id}
												variant={line.variant}
												item={line.item}
												qty={Number(line.qty).toFixed(0)}
												qty_pack={Number(line.qty_pack).toFixed(0)}
												amount={Number(line.amount)}
												unitAmount={Number(line.unitAmount)}
												images={line.overview_image}
												hidePackaging
												hideButtons
												hideRange
												hideStock
												resumeOrder
												buyAgain
											/>

											{/*{report.orderline.length !== index + 1 && <hr />}*/}
										</div>
									))}
							</div>
						)}
						<div className={classes.buttons}>
							{orderConfigurations && orderConfigurations.length <= 1 && (
								<Button
									className={classes.btn}
									size="big"
									onClick={() => {
										if (orderConfigurations && orderConfigurations.length > 0)
											dispatch(
												printReport({
													id: orderConfigurations[0].id,
													code: orderConfigurations[0].erp_code,
												})
											);
									}}
									disabled={isPrinting || orderConfigurations.length === 0}
								>
									{t(keys.ACCOUNT.ORDERS.VIEWCS)}
								</Button>
							)}
							{orderConfigurations && orderConfigurations.length > 1 && (
								<div className={classes.reportLayout}>
									<p>{t(keys.ACCOUNT.ORDERS.VIEWCS)}</p>
									{orderConfigurations.map((report) => (
										<div
											key={report.id}
											className={classes.report}
											onClick={() => {
												if (!isPrinting)
													dispatch(
														printReport({
															id: report.id,
															code: report.erp_code,
														})
													);
											}}
										>
											{report.erp_code}
										</div>
									))}
								</div>
							)}
							{orderPackings && orderPackings.length <= 1 && (
								<Button
									className={classes.btn}
									size="big"
									onClick={() => {
										if (orderPackings && orderPackings.length > 0)
											dispatch(
												printReport({
													id: orderPackings[0].id,
													code: orderPackings[0].erp_code,
												})
											);
									}}
									disabled={isPrinting || orderPackings.length === 0}
								>
									{t(keys.ACCOUNT.ORDERS.VIEWPS)}
								</Button>
							)}
							{orderPackings && orderPackings.length > 1 && (
								<div className={classes.reportLayout}>
									<p>{t(keys.ACCOUNT.ORDERS.VIEWPS)}</p>
									{orderPackings.map((report) => (
										<div
											key={report.id}
											className={classes.report}
											onClick={() => {
												if (!isPrinting)
													dispatch(
														printReport({
															id: report.id,
															code: report.erp_code,
														})
													);
											}}
										>
											{report.erp_code}
										</div>
									))}
								</div>
							)}
							{orderInvoices && orderInvoices.length <= 1 && (
								<Button
									className={classes.btn}
									size="big"
									onClick={() => {
										if (orderInvoices && orderInvoices.length > 0) {
											dispatch(
												printReport({
													id: orderInvoices[0].id,
													code: orderInvoices[0].erp_code,
												})
											);
										}
									}}
									disabled={isPrinting || orderInvoices.length === 0}
								>
									{t(keys.ACCOUNT.ORDERS.VIEWIS)}
								</Button>
							)}
							<Button
								className={classes.btnBuyAgain}
								size="big"
								disabled={isCartLoading}
								onClick={() => {
									dispatch(copyOrder(props.id));
								}}
								content={classes.buyAgain}
								color={"outlineBlue"}
							>
								{t(keys.ACCOUNT.ORDERS.BUYALLAGAIN)}
							</Button>
							{orderInvoices && orderInvoices.length > 1 && (
								<div className={classes.reportLayout}>
									<p>{t(keys.ACCOUNT.ORDERS.VIEWIS)}</p>
									{orderInvoices.map((report) => (
										<div
											key={report.id}
											className={classes.report}
											onClick={() => {
												if (!isPrinting)
													dispatch(
														printReport({
															id: report.id,
															code: report.erp_code,
														})
													);
											}}
										>
											{report.erp_code}
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrderItem;
