import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import useElementSize from "../../../hooks/useElementSize";

import Loading from "../../ui/loading/Loading";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import Button from "../../ui/Button/Button";
import Accordion from "../../ui/Accordion/Accordion";
import LinkTree from "../../ui/LinkTree/LinkTree";

import { categoriesSelector, getLibrary, isLoadingSelector } from "../../../store/architect";
import { LibraryHeaderSelector } from "../../../store/mediaBase";

import { keys } from "../../../locales/localeskeys";

import classes from "./Library.module.scss";
import TextInputOverlay from "../../ui/Input/TextInputOverlay/TextInputOverlay";
import {
	clearLibrary,
	getLibraries,
	libraryCountSelector,
	libraryIsLoadingSelector,
	librarySelector,
} from "../../../store/library";

const Library = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const [gridRef, { width: gridWidth }] = useElementSize();

	const dispatch = useDispatch();

	const [fileState, setFileState] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [libraryPage, setLibraryPage] = useState(1);
	const [isExpanded, setIsExpanded] = useState(false);

	const categories = useSelector(categoriesSelector);
	const libraryIsLoadingdocument = useSelector(libraryIsLoadingSelector);
	const libraryIsLoading = useSelector(isLoadingSelector);
	const libraryHeader = useSelector(LibraryHeaderSelector);
	const library = useSelector(librarySelector);
	const libraryCount = useSelector(libraryCountSelector);

	useEffect(() => {
		dispatch(getLibrary());
		setFileState("");
		setIsSubmitted(false);
		dispatch(clearLibrary());
	}, []);

	useEffect(() => {
		if (isSubmitted)
			dispatch(
				getLibraries({
					category: 3,
					search: fileState,
					page: libraryPage,
					pageSize: 16,
					keep: true,
				})
			);
	}, [libraryPage]);
	const fileChangeHandler = (event) => {
		setFileState(event.target.value);
		if (event.target.value.trim() === "") {
			setIsSubmitted(false);
		}
	};
	const searchSubmit = (event) => {
		event.preventDefault();
		if (!isSubmitted) {
			setIsSubmitted(true);
			dispatch(
				getLibraries({
					category: 3,
					search: fileState,
					page: libraryPage,
					pageSize: 16,
					keep: false,
				})
			);
		} else {
			setFileState("");
			setIsSubmitted(false);
			dispatch(clearLibrary());
		}
	};

	const loadMore = () => {
		if (libraryCount > libraryPage * 16) setLibraryPage(libraryPage + 1);
	};

	const toggleExpand = () => setIsExpanded(!isExpanded);

	function splitParagraph(paragraph) {
		const words = paragraph.split(" ");
		const first20Words = words.slice(0, 20).join(" ");
		const remainingWords = words.slice(20).join(" ");

		return {
			first20Words,
			remainingWords,
		};
	}

	return (
		<div ref={gridRef}>
			{(!libraryHeader || libraryIsLoading) && <Loading className={classes.loading} type={"bounce"} />}
			{!libraryIsLoading && libraryHeader && libraryHeader.length > 0 && categories && categories.length > 0 && (
				<Fragment>
					<HeaderCarousel className={classes.carousel} data={libraryHeader} />
					<div className={classes.layout}>
						<LinkTree
							className={classes.Tree}
							tree={[
								{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
								{ label: t(keys.ARCHITECT.TITLE), to: "/architect/" },
								{ label: t(keys.ARCHITECT.LIBRARYTXT), to: "/architect/library/" },
							]}
						/>
						<h1 className={classes.title}>{t(keys.ARCHITECT.LIBRARY.TITLE)}</h1>
						<p className={classes.description}>
							{(() => {
								const description = lngId?.includes("fr")
									? libraryHeader[0].description_fr
									: libraryHeader[0].description_en;
								const { first20Words, remainingWords } = splitParagraph(description);

								if (gridWidth > 833 || !remainingWords) {
									return description;
								} else {
									return (
										<>
											{isExpanded ? description : `${first20Words}...`}
											<span onClick={toggleExpand} className={classes.seenMoreButton}>
												{isExpanded
													? t(keys.HOME.BUTTON_SEE_LESS)
													: t(keys.HOME.BUTTON_SEE_MORE)}
											</span>
										</>
									);
								}
							})()}
						</p>
						<div className={classes.center}>
							<CustomNavLink to={"/contact-us"}>
								<Button className={classes.btn} style={{ background: "black", borderColor: "black" }}>
									{t(keys.ARCHITECT.LIBRARY.REQUEST)}
								</Button>
							</CustomNavLink>
							<h2>{t(keys.ARCHITECT.LIBRARY.SDS_TDS)}</h2>
							<hr />
							<div className={classes.inputFile}>
								<TextInputOverlay
									label={t(keys.ARCHITECT.LIBRARY.LABEL)}
									className={classes.inputOverlay}
									labelclass={classes.labelclass}
									value={fileState}
									onChange={fileChangeHandler}
									disabled={libraryIsLoading || isSubmitted}
									sdsTds
								/>
								<Button
									className={classes.btn}
									color={isSubmitted ? "outlineBlue" : ""}
									size="big"
									weight={"bold"}
									onClick={searchSubmit}
								>
									{isSubmitted ? t(keys.ARCHITECT.LIBRARY.RESET) : t(keys.ARCHITECT.LIBRARY.SEARCH)}
								</Button>
							</div>
							{!libraryIsLoading && library && library.length > 0 && (
								<div className={classes.libraryDocuments}>
									{library
										.filter((document) => document.lng.includes(lngId.substring(0, 2)))
										.map((document) => (
											<CustomNavLink to={document.url} target={"_blank"}>
												<div key={document.id} className={classes.document}>
													<div>
														<h1>{document.title}</h1>
														<p>{document.description}</p>
													</div>

													<Icon className={classes.icon} icon="mdi:download-box-outline" />
												</div>
											</CustomNavLink>
										))}
								</div>
							)}
							{!libraryIsLoadingdocument && isSubmitted && library && library.length <= 0 && (
								<div className={classes.noResult}>{t(keys.ARCHITECT.LIBRARY.NO_RESULT_FOUND)}</div>
							)}
							{isSubmitted && libraryCount > libraryPage * 16 && (
								<Button
									color="outlineBlue"
									size="big"
									onClick={loadMore}
									className={classes.loadBtn}
									disable={libraryIsLoading}
								>
									<p>{t(keys.SEARCH.LOAD)}</p>
								</Button>
							)}
							<h2>{t(keys.ARCHITECT.LIBRARY.RESSOURCES)}</h2>
							<hr />
						</div>
						{categories && categories.length > 0 && (
							<div className={classes.accordionLayout}>
								{categories.map((category) => (
									<Accordion
										key={category.id}
										className={classes.accordion}
										headerClass={classes.headerClass}
										iconPlusMinus={classes.iconPlusMinus}
										header={lngId.includes("fr") ? category.title_fr : category.title_en}
										dark
									>
										{category.libraries?.map((library) => (
											<div key={library.id} className={classes.library}>
												<div className={classes.content}>
													<div className={classes.text}>
														<h3>
															{lngId.includes("fr") ? library.title_fr : library.title_en}
														</h3>
														<p>
															{lngId.includes("fr")
																? library?.description_fr
																: library?.description_en}
														</p>
													</div>
													<CustomNavLink
														to={lngId.includes("fr") ? library?.url_fr : library?.url_en}
														target={"_blank"}
													>
														<Icon
															className={classes.icon}
															icon="mdi:download-box-outline"
														/>
													</CustomNavLink>
												</div>
												<hr />
											</div>
										))}
									</Accordion>
								))}
							</div>
						)}
						<div className={classes.contactLayout}>
							<h1>{t(keys.HOME.NEED_HELP)}</h1>
							<CustomNavLink className={classes.btnLink} to={"/contact-us"}>
								<Button className={classes.btn}>{t(keys.HOME.CONTACT_US)}</Button>
							</CustomNavLink>
						</div>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default Library;
