import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des media
 */
export const getMediaBase = createAsyncThunk("mediaBase/getMediaBase", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/media_base/");
		if (!response.ok) {
			throw new Error("fetching mediaBase failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

const mediaBaseAdapter = createEntityAdapter({});

const mediaBase = createSlice({
	name: "mediaBase",
	initialState: mediaBaseAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getMediaBase.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				mediaBaseAdapter.removeAll(state);
				mediaBaseAdapter.addMany(state, payload);
			}
			return state;
		},
		[getMediaBase.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getMediaBase.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default mediaBase;
export const mediaBaseSelectors = mediaBaseAdapter.getSelectors((state) => state?.mediaBase);
export const mediaBaseSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) => mediaBases);
export const homeHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 0 && media.media_type === 0)
);
export const ManufacturingHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 1 && media.media_type === 0)
);
export const ConstructionHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 2 && media.media_type === 0)
);
export const homeSegmentPickerSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 0 && media.media_type === 1)
);
export const homeDiscoverProductSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 0 && media.media_type === 2)
);
export const constructionDiscoverProductSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 2 && media.media_type === 2)
);
export const mediaBaseLoadingSelector = (state) => state?.mediaBase?.loading === "pending";
export const ArchitectHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 3 && media.media_type === 0)
);
export const ArchitectPickerSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 3 && media.media_type === 1)
);
export const webinarHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 4 && media.media_type === 0)
);
export const visitHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 5 && media.media_type === 0)
);
export const LibraryHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 6 && media.media_type === 0)
);
export const EventHeaderSelector = createSelector(mediaBaseSelectors.selectAll, (mediaBases) =>
	mediaBases.filter((media) => media.media_page === 7 && media.media_type === 0)
);
