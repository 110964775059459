import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import LinkTree from "../ui/LinkTree/LinkTree";
import Dropdown from "../ui/Input/Dropdown/Dropdown";
import TextInput from "../ui/Input/TextInput/TextInput";
import Button from "../ui/Button/Button";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import saucisse from "../../assets/png/dessin-saucisse.png";
import tube from "../../assets/svg/dessin-tube.svg";
import logo from "../../assets/svg/adtools-logo.svg";
import colorMatching from "../../assets/svg/color-matching.svg";
import productCompatibility from "../../assets/svg/product-compatibility.svg";
import productSelector from "../../assets/svg/product-selector.svg";
import backerod_1 from "../../assets/png/backerod_1.png";

import { keys } from "../../locales/localeskeys";

import classes from "./SealantCalculator.module.scss";
import { constants } from "../../locales/constant";

const SealantCalculator = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const search = `?lng=${lngId}`;

	const [feet, setFeet] = useState(0);
	const [dimension, setDimension] = useState("");

	const [cartridges, setCartridges] = useState({ value: 0, isLoading: false });
	const [sausages, setSausages] = useState({ value: 0, isLoading: false });

	const accessoire_imgs = [
		"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/OnlineTools/Adseal600MG_Nozzlesausage.png",
		"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/OnlineTools/Adseal600MG_2N1600.png",
		"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Product_Pictures/Gun_Mockup_EN.png",
	];
	const [accessoire_cur, setAccessoire_cur] = useState(
		"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/OnlineTools/Adseal600MG_Nozzlesausage.png"
	);

	const backerod_imgs = [
		"https://adfastcorpcdnak.azureedge.net/b2b/Product_Pictures/ADSEAL-BR-2600X1-1_8.jpg",
		"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/OnlineTools/Backerrod_EN.png",
	];
	const [backerod_cur, setBackerod_cur] = useState(backerod_1);

	const calculateSealant = () => {
		setCartridges({ value: 0, isLoading: true });
		setSausages({ value: 0, isLoading: true });

		setTimeout(() => {
			let sausage = 0;
			let cartridge = 0;

			constants.calculatorData.forEach((item) => {
				if (item.dimension === dimension) {
					sausage = item.sausage;
					cartridge = item.cartridge;
				}
			});

			setCartridges({ value: Math.ceil(feet / cartridge), isLoading: false });
			setSausages({ value: Math.ceil(feet / sausage), isLoading: false });
		}, 350);
	};

	const reset = () => {
		setDimension(t(keys.TOOLS.SEALANTCALCULATOR.DIMENSION));
		setFeet(0);
		setCartridges({ value: 0, isLoading: true });
		setSausages({ value: 0, isLoading: true });

		setTimeout(() => {
			setCartridges({ value: 0, isLoading: false });
			setSausages({ value: 0, isLoading: false });
		}, 350);
	};

	return (
		<div className={classes.layout}>
			<LinkTree
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
					{ label: t(keys.TOOLS.TITLE), to: "/" },
					{ label: t(keys.TOOLS.SEALANTCALCULATOR.TITLE), to: "/tools/sealant-calculator/" },
				]}
			/>
			<h1 className={classes.title}>{t(keys.TOOLS.SEALANTCALCULATOR.TITLE)}</h1>
			<p className={classes.description}>{t(keys.TOOLS.SEALANTCALCULATOR.DESCRIPTION)}</p>
			<div className={classes.body}>
				<div className={classes.calculator}>
					<div className={classes.dimensions}>
						<h2>{t(keys.TOOLS.SEALANTCALCULATOR.DIMENSION_TITLE)}</h2>
						<Dropdown
							className={classes.inputJoint}
							onChange={(event) => {
								setDimension(event.target.value);
							}}
							wrapperClassName={classes.after}
							value={dimension}
						>
							<option id={0}>{t(keys.TOOLS.SEALANTCALCULATOR.DIMENSION)}</option>
							{constants.calculatorData.map((item, index) => {
								return (
									<option key={index} id={index}>
										{item.dimension}
									</option>
								);
							})}
						</Dropdown>
					</div>
					<div className={classes.numbers}>
						<h2>{t(keys.TOOLS.SEALANTCALCULATOR.FEET_TITLE)}</h2>
						<TextInput
							type="number"
							onChange={(event) => {
								setFeet(event.target.value);
							}}
							id="Coverage"
							placeholder={t(keys.TOOLS.SEALANTCALCULATOR.FEET)}
							className={classes.inputCoverage}
							inputClassName={classes.inputClassName}
							value={feet}
						/>
					</div>
					<div className={classes.btnContainer}>
						<Button className={classes.btn} content={classes.btnContent} onClick={calculateSealant}>
							{t(keys.TOOLS.SEALANTCALCULATOR.CALCULATE)}
						</Button>
					</div>
					<div className={classes.results}>
						<div className={classes.saussage}>
							<img src={tube} alt="tube.svg" />
							<p>{cartridges.isLoading ? "*" : cartridges.value}</p>
							<h3>{t(keys.TOOLS.SEALANTCALCULATOR.CARTRIDGE)}</h3>
						</div>
						<p className={classes.or}>{t(keys.TOOLS.SEALANTCALCULATOR.OR)}</p>
						<div className={classes.tube}>
							<img src={saucisse} alt="saucisse.svg" />
							<p>{sausages.isLoading ? "*" : sausages.value}</p>
							<h3>{t(keys.TOOLS.SEALANTCALCULATOR.SAUSAGE)}</h3>
						</div>
					</div>
					<div className={classes.reset} onClick={reset}>
						<p>{t(keys.TOOLS.SEALANTCALCULATOR.RESET)}</p>
					</div>
					<h2 className={classes.legal}>{t(keys.TOOLS.SEALANTCALCULATOR.LEGAL)}</h2>
				</div>
				<div className={classes.linksContainer}>
					<div className={classes.links}>
						<img src={logo} alt="tube.svg" />
						<CustomNavLink
							className={classes.element}
							to={
								lngId.includes("en")
									? "https://adfastcorp.com/en/find-the-right-sealant/"
									: "https://adfastcorp.com/trouver-le-bon-scellant/"
							}
							target={"_blank"}
						>
							<div className={classes.text}>
								<p>{t(keys.TOOLS.SEALANTCALCULATOR.COLOR_MATCH)}</p>
							</div>
							<img src={colorMatching} alt="tube.svg" />
						</CustomNavLink>
						<CustomNavLink
							className={classes.element}
							to={
								lngId.includes("en")
									? "https://adfastcorp.com/en/silicone-compatibility/"
									: "https://adfastcorp.com/compatibilite-silicones/"
							}
							target={"_blank"}
						>
							<div className={classes.text}>
								<p>{t(keys.TOOLS.SEALANTCALCULATOR.PRODUCT_COMPATIBILITY)}</p>
							</div>
							<img src={productCompatibility} alt="tube.svg" />
						</CustomNavLink>
						<CustomNavLink
							className={classes.element}
							to={
								lngId.includes("en")
									? "https://adfastcorp.com/en/guided-product-selector/"
									: "https://adfastcorp.com/product-finder/"
							}
							target={"_blank"}
						>
							<div className={classes.text}>
								<p>{t(keys.TOOLS.SEALANTCALCULATOR.PRODUCT_SELECTOR)}</p>
							</div>
							<img src={productSelector} alt="tube.svg" />
						</CustomNavLink>
					</div>
				</div>
			</div>
			<div className={classes.relatedProduct}>
				<h1 className={classes.title}>{t(keys.TOOLS.SEALANTCALCULATOR.RELATED_PRODUCT)}</h1>
				<div className={classes.element}>
					<div className={classes.imgs}>
						<div className={classes.second}>
							{accessoire_imgs
								.filter((img) => img !== accessoire_cur)
								.map((acc_img, index) => (
									<img
										key={index}
										src={acc_img}
										alt={acc_img}
										onClick={() => {
											setAccessoire_cur(acc_img);
										}}
									/>
								))}
						</div>
						<div className={classes.main}>
							<img src={accessoire_cur} alt={accessoire_cur} />
						</div>
					</div>
					<div className={classes.detail}>
						<h1>{t(keys.TOOLS.SEALANTCALCULATOR.ACCESSOIRE_TITLE)}</h1>
						<p>
							{t(keys.TOOLS.SEALANTCALCULATOR.ACCESSOIRE_DESC1)}{" "}
							<CustomNavLink className={classes.link} to={`/adstore/${search}&categories=007`}>
								{t(keys.TOOLS.SEALANTCALCULATOR.ACCESSOIRE_LINK)}
							</CustomNavLink>{" "}
							{t(keys.TOOLS.SEALANTCALCULATOR.ACCESSOIRE_DESC2)}
						</p>
					</div>
				</div>
				<div className={classes.element}>
					<div className={classes.imgs}>
						<div className={classes.second}>
							{backerod_imgs
								.filter((img) => img !== backerod_cur)
								.map((acc_img, index) => (
									<img
										key={index}
										src={acc_img}
										alt={acc_img}
										onClick={() => {
											setBackerod_cur(acc_img);
										}}
									/>
								))}
						</div>
						<div className={classes.main}>
							<img src={backerod_cur} alt={backerod_cur} />
						</div>
					</div>
					<div className={classes.detail}>
						<h1>{t(keys.TOOLS.SEALANTCALCULATOR.BACKEROD_TITLE)}</h1>
						<p>
							{t(keys.TOOLS.SEALANTCALCULATOR.BACKEROD_DESC1)}{" "}
							<CustomNavLink className={classes.link} to={`/adstore/${search}&categories=009`}>
								{t(keys.TOOLS.SEALANTCALCULATOR.BACKEROD_LINK)}
							</CustomNavLink>{" "}
							{t(keys.TOOLS.SEALANTCALCULATOR.BACKEROD_DESC2)}
							<CustomNavLink className={classes.link} to={`/adstore/${search}&categories=008`}>
								{t(keys.TOOLS.SEALANTCALCULATOR.SETTING_LINK)}
							</CustomNavLink>
							{"."}
						</p>
					</div>
				</div>
				<div className={classes.melements}>
					<CustomNavLink className={classes.melement} to={`/adstore/${search}&categories=007`}>
						<img src={accessoire_imgs[0]} alt="accessorie_4" />
						<Button className={classes.btn}>
							<div className={classes.content}>{t(keys.TOOLS.SEALANTCALCULATOR.ACCESSOIRE_TITLE1)}</div>
							<div className={classes.content}>{t(keys.TOOLS.SEALANTCALCULATOR.ACCESSOIRE_TITLE2)}</div>
						</Button>
					</CustomNavLink>
					<CustomNavLink className={classes.melement} to={`/adstore/${search}&categories=008%2C009`}>
						<img src={backerod_imgs[0]} alt="backerod_1" />
						<Button className={classes.btn}>
							<div className={classes.content}>{t(keys.TOOLS.SEALANTCALCULATOR.BACKEROD_TITLE1)}</div>
							<div className={classes.content}>{t(keys.TOOLS.SEALANTCALCULATOR.BACKEROD_TITLE2)}</div>
						</Button>
					</CustomNavLink>
				</div>
			</div>
			<div className={classes.contactLayout}>
				<h1>{t(keys.HOME.NEED_HELP)}</h1>
				<CustomNavLink className={classes.btnLink} to={"/contact-us"}>
					<Button className={classes.btn}>{t(keys.HOME.CONTACT_US)}</Button>
				</CustomNavLink>
			</div>
		</div>
	);
};

export default SealantCalculator;
