import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SearchBar from "../../ui/Input/SearchBar/SearchBar";
import Dropdown from "../../ui/Input/Dropdown/Dropdown";
import OrderItem from "./orderItem/OrderItem";
import Loading from "../../ui/loading/Loading";
import Pagination from "../../ui/Pagination/Pagination";
import LinkTree from "../../ui/LinkTree/LinkTree";

import { isLoggedInSelector, setPrecedentUrl } from "../../../store/auth";
import {
	countSelector,
	getReports,
	isLoadingSelector,
	monthFilterSelector,
	pageSelector,
	reportsSelector,
	searchFilterSelector,
	setMonthFilter,
	setPage,
	setSearchFilter,
	setStatusFilter,
	setYearFilter,
	statusFilterSelector,
	yearFilterSelector,
} from "../../../store/report";

import { keys } from "../../../locales/localeskeys";

import classes from "./Orders.module.scss";

const Orders = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoading = useSelector(isLoadingSelector);
	const searchFilter = useSelector(searchFilterSelector);
	const yearFilter = useSelector(yearFilterSelector);
	const monthFilter = useSelector(monthFilterSelector);
	const statusFilter = useSelector(statusFilterSelector);
	const currentPage = useSelector(pageSelector);
	const totalOrders = useSelector(countSelector);
	const reports = useSelector(reportsSelector);

	const months = [
		t(keys.GLOBAL.COMMON.MONTH),
		t(keys.GLOBAL.COMMON.JANUARY),
		t(keys.GLOBAL.COMMON.FEBRUARY),
		t(keys.GLOBAL.COMMON.MARCH),
		t(keys.GLOBAL.COMMON.APRIL),
		t(keys.GLOBAL.COMMON.MAY),
		t(keys.GLOBAL.COMMON.JUNE),
		t(keys.GLOBAL.COMMON.JULY),
		t(keys.GLOBAL.COMMON.AUGUST),
		t(keys.GLOBAL.COMMON.SEPTEMBER),
		t(keys.GLOBAL.COMMON.OCTOBER),
		t(keys.GLOBAL.COMMON.NOVEMBER),
		t(keys.GLOBAL.COMMON.DECEMBER),
	];
	const currentYear = new Date().getFullYear();
	const years = [
		currentYear - 5,
		currentYear - 4,
		currentYear - 3,
		currentYear - 2,
		currentYear - 1,
		currentYear,
		currentYear + 1,
	];

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	useEffect(() => {
		if (isLoggedIn === true) {
			dispatch(getReports());
		}
	}, [isLoggedIn]);

	const searchChangeHandler = (searchValue) => {
		dispatch(setSearchFilter(searchValue));
	};

	const searchSubmitHandler = (event) => {
		event.preventDefault();
		dispatch(getReports());
	};

	const searchEraseHandler = () => {
		dispatch(setSearchFilter(""));
		dispatch(getReports());
	};

	const monthChangeHandler = (event) => {
		dispatch(setMonthFilter(event.target.value));
		dispatch(getReports());
	};

	const yearChangeHandler = (event) => {
		dispatch(setYearFilter(event.target.value));
		dispatch(getReports());
	};

	const statusChangeHandler = (event) => {
		dispatch(setStatusFilter(event.target.value));
		dispatch(getReports());
	};

	const changePageHandler = (page) => {
		dispatch(setPage(page));
		dispatch(getReports());
	};

	return (
		<div className={classes.bodyContainer}>
			<LinkTree
				className={classes.Tree}
				tree={[
					{ label: t(keys.ACCOUNT.ACCOUNT.TITLE), to: `/account?lng=${lngId}` },
					{ label: t(keys.ACCOUNT.ORDERS.TITLE), to: `/orders?lng=${lngId}` },
				]}
			/>
			<h1 className={classes.title}>{t(keys.ACCOUNT.ORDERS.TITLE)}</h1>
			<div className={classes.searchLayout}>
				<SearchBar
					id="search_order"
					placeholder={t(keys.GLOBAL.SEARCH)}
					value={searchFilter ? searchFilter : ""}
					onChange={(value) => searchChangeHandler(value)}
					onClick={() => searchEraseHandler()}
					onSubmit={searchSubmitHandler}
					className={classes.searchField}
					disabled={isLoading}
				/>
				<div className={classes.text}>Refine your search by:</div>
				<div className={classes.date}>
					<Dropdown
						value={monthFilter ? monthFilter : -1}
						onChange={monthChangeHandler}
						disabled={isLoading}
						dropdown={classes.month}
						wrapperClassName={classes.wrapperClassName}
					>
						{months.map((month, index) => (
							<option key={index} value={index}>
								{month}
							</option>
						))}
					</Dropdown>
					<Dropdown
						value={yearFilter ? yearFilter : -1}
						onChange={yearChangeHandler}
						disabled={isLoading}
						dropdown={classes.year}
						wrapperClassName={classes.wrapperClassName}
					>
						<option value={-1}>{t(keys.GLOBAL.COMMON.YEAR)}</option>
						{years.map((year, index) => (
							<option key={index} value={year}>
								{year}
							</option>
						))}
					</Dropdown>
				</div>
				<Dropdown
					value={statusFilter ? statusFilter : -1}
					onChange={statusChangeHandler}
					disabled={isLoading}
					dropdown={classes.status}
					wrapperClassName={classes.wrapperClassName}
				>
					<option value={-1}>{t(keys.GLOBAL.COMMON.STATUS)}</option>
					<option value={0}>{t(keys.ACCOUNT.ORDERS.OPEN)}</option>
					<option value={1}>{t(keys.ACCOUNT.ORDERS.CONFIRMED)}</option>
					<option value={2}>{t(keys.ACCOUNT.ORDERS.DELIVERED)}</option>
					<option value={3}>{t(keys.ACCOUNT.ORDERS.INVOICED)}</option>
					<option value={4}>{t(keys.ACCOUNT.ORDERS.CANCELED)}</option>
				</Dropdown>
			</div>
			<div className={classes.orderslayout}>
				{isLoading && <Loading type={"linear"} />}
				{!isLoading && reports && reports.map((report) => <OrderItem key={report.id} id={report.id} />)}
			</div>
			<Pagination
				className={classes.pagination}
				currentPage={currentPage}
				totalCount={totalOrders}
				pageSize={5}
				onPageChange={(page) => changePageHandler(page)}
			/>
		</div>
	);
};

export default Orders;
