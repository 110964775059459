import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import HTMLString from "react-html-string";

import Accordion from "../Accordion/Accordion";

import {
	applicationsSelector,
	detailsSelector,
	itemTechnicalSelectorGroupedSelector,
	packagingSelector,
	specificationsSelector,
	technical_descriptionSelector,
	usagesSelector,
} from "../../../store/product";

import { keys } from "../../../locales/localeskeys";

import classes from "./TechnicalOverview.module.scss";

const TechnicalOverview = (props) => {
	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const technical_description = useSelector(technical_descriptionSelector);
	const specifications = useSelector(specificationsSelector);
	const usages = useSelector(usagesSelector);
	const details = useSelector(detailsSelector);
	const applications = useSelector(applicationsSelector);
	const packaging = useSelector(packagingSelector);
	const itemTechnicalSelectorGrouped = useSelector(itemTechnicalSelectorGroupedSelector);

	const overview_ref = useRef();
	const specification_ref = useRef();
	const packaging_ref = useRef();
	const usage_ref = useRef();
	const detail_ref = useRef();
	const application_ref = useRef();
	const related_doc_ref = useRef();

	const [currentSegment, setCurrentSegment] = useState();

	useEffect(() => {
		if (currentSegment === 1) overview_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 2) specification_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 3) packaging_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 4) usage_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 5) detail_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 6) application_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 7) related_doc_ref?.current.scrollIntoView({ behavior: "smooth" });
	}, [currentSegment]);

	return (
		<Fragment>
			<div className={classes.layout}>
				{(technical_description ||
					(details && details.length > 0) ||
					(applications && applications.length) ||
					(itemTechnicalSelectorGrouped && itemTechnicalSelectorGrouped.length > 0)) && (
					<div className={classes.header}>
						{technical_description &&
							(lngId.includes("fr") ? technical_description.fr : technical_description.en) && (
								<h1
									id={1}
									style={{
										textAlign: "left",
										paddingLeft: "1rem",
										paddingBottom: currentSegment === 1 ? "0" : undefined,
										borderBottom: currentSegment === 1 ? "1rem solid #007AFF" : undefined,
									}}
									onClick={() => setCurrentSegment(1)}
								>
									{t(keys.PRODUCTS.OVERVIEW.OVERVIEW)}
								</h1>
							)}
						{details && details.length > 0 && (
							<h1
								id={2}
								style={{
									textAlign: "center",
									paddingBottom: currentSegment === 5 ? "0" : undefined,
									borderBottom: currentSegment === 5 ? "1rem solid #007AFF" : undefined,
								}}
								onClick={() => setCurrentSegment(5)}
							>
								{t(keys.PRODUCTS.OVERVIEW.DETAILS)}
							</h1>
						)}
						{applications && applications.length > 0 && (
							<h1
								id={3}
								style={{
									textAlign: "center",
									paddingBottom: currentSegment === 6 ? "0" : undefined,
									borderBottom: currentSegment === 6 ? "1rem solid #007AFF" : undefined,
								}}
								onClick={() => setCurrentSegment(6)}
							>
								{t(keys.PRODUCTS.OVERVIEW.APPLICATION)}
							</h1>
						)}
						{itemTechnicalSelectorGrouped && itemTechnicalSelectorGrouped.length > 0 && (
							<h1
								id={4}
								style={{
									textAlign: "right",
									paddingRight: "1rem",
									paddingBottom: currentSegment === 7 ? "0" : undefined,
									borderBottom: currentSegment === 7 ? "1rem solid #007AFF" : undefined,
								}}
								onClick={() => setCurrentSegment(7)}
							>
								{t(keys.PRODUCTS.OVERVIEW.RELATED_DOC)}
							</h1>
						)}
					</div>
				)}
				<div className={classes.body}>
					{technical_description &&
						(lngId.includes("fr") ? technical_description.fr : technical_description.en) && (
							<div className={classes.segment} ref={overview_ref}>
								<h1
									onClick={() => setCurrentSegment(1)}
									style={{
										backgroundColor: currentSegment === 1 ? "#007AFF" : undefined,
										color: currentSegment === 1 ? "white" : undefined,
									}}
								>
									{t(keys.PRODUCTS.OVERVIEW.OVERVIEW)}
								</h1>
								{lngId.includes("fr")
									? technical_description.fr
									: technical_description.en && (
											<div className={classes.content}>
												<HTMLString
													html={
														lngId.includes("fr")
															? technical_description.fr
															: technical_description.en
													}
												/>
											</div>
									  )}
							</div>
						)}
					{specifications && specifications.length > 0 && (
						<div className={classes.segment} ref={specification_ref}>
							<h1
								onClick={() => setCurrentSegment(2)}
								style={{
									backgroundColor: currentSegment === 2 ? "#007AFF" : undefined,
									color: currentSegment === 2 ? "white" : undefined,
								}}
							>
								{t(keys.PRODUCTS.OVERVIEW.SPECIFICATIONS)}
							</h1>
							<div className={classes.content}>
								<ul className={classes.specElement}>
									{specifications
										.filter((s) => (lngId.includes("fr") ? s.text_fr : s.text_en))
										.map((s) => (
											<li key={s.id}>{lngId.includes("fr") ? s.text_fr : s.text_en}</li>
										))}
								</ul>
								<div className={classes.imgWrapper}>
									{specifications
										.filter((s) => (lngId.includes("fr") ? s.url_fr : s.url_en))
										.map((s) => (
											<img
												src={lngId.includes("fr") ? s.url_fr : s.url_en}
												alt="specification images"
											/>
										))}
								</div>
							</div>
						</div>
					)}
					{packaging && packaging.length > 0 && (
						<div className={classes.segment} ref={packaging_ref}>
							<h1
								onClick={() => setCurrentSegment(3)}
								style={{
									backgroundColor: currentSegment === 3 ? "#007AFF" : undefined,
									color: currentSegment === 3 ? "white" : undefined,
								}}
							>
								{t(keys.PRODUCTS.OVERVIEW.PACKAGING)}
							</h1>
							<div className={classes.content}>
								<ul className={classes.specElement}>
									{packaging
										.filter((p) => (lngId.includes("fr") ? p.description_fr : p.description_en))
										.map((p) => (
											<li key={p.id}>
												{lngId.includes("fr") ? p.description_fr : p.description_en}
											</li>
										))}
								</ul>
							</div>
						</div>
					)}
					{usages && usages.length > 0 && (
						<div className={classes.segment} ref={usage_ref}>
							<h1
								onClick={() => setCurrentSegment(4)}
								style={{
									backgroundColor: currentSegment === 4 ? "#007AFF" : undefined,
									color: currentSegment === 4 ? "white" : undefined,
								}}
							>
								{t(keys.PRODUCTS.OVERVIEW.USAGE)}
							</h1>
							<div className={classes.content}>
								<ul className={classes.usageElement}>
									{usages.map((u) => (
										<li key={u.group.id}>
											<h2>{lngId.includes("fr") ? u.group.label_fr : u.group.label_en}</h2>
											<ul>
												{u.usages.map((x) => (
													<li key={x.id}>{lngId.includes("fr") ? x.text_fr : x.text_en}</li>
												))}
											</ul>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
					{details && details.length > 0 && (
						<div className={classes.segment} ref={detail_ref}>
							<h1
								onClick={() => setCurrentSegment(5)}
								style={{
									backgroundColor: currentSegment === 5 ? "#007AFF" : undefined,
									color: currentSegment === 5 ? "white" : undefined,
								}}
							>
								{t(keys.PRODUCTS.OVERVIEW.DETAILS)}
							</h1>
							<div className={classes.content}>
								{details.map((d) => (
									<div key={d.group.id} className={classes.detailElement}>
										<h2>{lngId.includes("fr") ? d.group.label_fr : d.group.label_en}</h2>
										<div className={classes.array}>
											{d.details.map((x) => (
												<div key={x.id} className={classes.row}>
													<p className={classes.col}>
														{lngId.includes("fr") ? x.key_fr : x.key_en}
													</p>
													{lngId.includes("fr")
														? x.value_fr
														: x.value_en && (
																<p className={classes.col}>
																	<HTMLString
																		html={
																			lngId.includes("fr")
																				? x.value_fr
																				: x.value_en
																		}
																	/>
																</p>
														  )}
												</div>
											))}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{applications && applications.length > 0 && (
						<div className={classes.segment} ref={application_ref}>
							<h1
								onClick={() => setCurrentSegment(6)}
								style={{
									backgroundColor: currentSegment === 6 ? "#007AFF" : undefined,
									color: currentSegment === 6 ? "white" : undefined,
								}}
							>
								{t(keys.PRODUCTS.OVERVIEW.APPLICATION)}
							</h1>
							<div className={classes.content}>
								<ul className={classes.applicationElement}>
									{applications.map((a) => (
										<div className={classes.container}>
											<h2>{lngId.includes("fr") ? a.title_fr : a.title_en}</h2>
											{lngId.includes("fr")
												? a.description_fr
												: a.description_en && (
														<div>
															<HTMLString
																html={
																	lngId.includes("fr")
																		? a.description_fr
																		: a.description_en
																}
															/>
														</div>
												  )}
										</div>
									))}
								</ul>
							</div>
						</div>
					)}
					{itemTechnicalSelectorGrouped && itemTechnicalSelectorGrouped.length > 0 && (
						<div className={classes.segment} ref={related_doc_ref}>
							<h1
								onClick={() => setCurrentSegment(7)}
								style={{
									backgroundColor: currentSegment === 7 ? "#007AFF" : undefined,
									color: currentSegment === 7 ? "white" : undefined,
								}}
							>
								{t(keys.PRODUCTS.OVERVIEW.RELATED_DOC)}
							</h1>
							{itemTechnicalSelectorGrouped.map((g) => (
								<Accordion
									className={classes.documentsSection}
									header={lngId.includes("fr") ? g.group.label_fr : g.group.label_en}
									headerClass={classes.accordionHeader}
									changeColor
									active
								>
									<div className={classes.documentsContent}>
										{g.documents
											?.filter(
												(doc) => doc.language && doc.language.includes(lngId.substring(0, 2))
											)
											.map((doc) => (
												<div
													className={classes.documentsButton}
													key={doc.id}
													onClick={() => window.open(doc.url, "_blank")}
												>
													<Icon
														className={classes.documentsIconBlack}
														icon="icomoon-free:file-pdf"
													/>
													<p>{doc.title.substring(0, 65)}</p>
													<Icon
														className={classes.iconDownload}
														icon="iconoir:download-circled-outline"
													/>
												</div>
											))}
									</div>
								</Accordion>
							))}
						</div>
					)}
				</div>
			</div>
			{technical_description && (lngId.includes("fr") ? technical_description.fr : technical_description.en) && (
				<div className={classes.content}>
					<HTMLString html={lngId.includes("fr") ? technical_description.fr : technical_description.en} />
				</div>
			)}
			<div className={classes.layoutMobile}>
				{technical_description && (lngId.includes("fr") ? technical_description.fr : technical_description.en) && (
					<div className={classes.Container}>
						<Accordion header={t(keys.PRODUCTS.OVERVIEW.OVERVIEW)}>
							{lngId.includes("fr")
								? technical_description.fr
								: technical_description.en && (
										<div className={classes.content}>
											<HTMLString
												html={
													lngId.includes("fr")
														? technical_description.fr
														: technical_description.en
												}
											/>
										</div>
								  )}
						</Accordion>
					</div>
				)}
				{specifications && specifications.length > 0 && (
					<div className={classes.Container}>
						<Accordion header={t(keys.PRODUCTS.OVERVIEW.SPECIFICATIONS)}>
							<div className={classes.content}>
								<ul className={classes.specElement}>
									{specifications
										.filter((s) => (lngId.includes("fr") ? s.text_fr : s.text_en))
										.map((s) => (
											<li key={s.id}>{lngId.includes("fr") ? s.text_fr : s.text_en}</li>
										))}
								</ul>
								<div className={classes.imgWrapper}>
									{specifications
										.filter((s) => (lngId.includes("fr") ? s.url_fr : s.url_en))
										.map((s) => (
											<img
												src={lngId.includes("fr") ? s.url_fr : s.url_en}
												alt="specification images"
											/>
										))}
								</div>
							</div>
						</Accordion>
					</div>
				)}
				{packaging && packaging.length > 0 && (
					<div className={classes.Container}>
						<Accordion
							header={t(keys.PRODUCTS.OVERVIEW.PACKAGING)}
							headerClass={classes.accordionContainer}
							iconClass={classes.iconClass}
						>
							<div className={classes.content}>
								<ul className={classes.specElement}>
									{packaging
										.filter((p) => (lngId.includes("fr") ? p.description_fr : p.description_en))
										.map((p) => (
											<li key={p.id}>
												{lngId.includes("fr") ? p.description_fr : p.description_en}
											</li>
										))}
								</ul>
							</div>
						</Accordion>
					</div>
				)}
				{usages && usages.length > 0 && (
					<div className={classes.Container}>
						<Accordion
							header={t(keys.PRODUCTS.OVERVIEW.USAGE)}
							headerClass={classes.accordionContainer}
							iconClass={classes.iconClass}
						>
							<div className={classes.content}>
								<ul className={classes.usageElement}>
									{usages.map((u) => (
										<li key={u.group.id}>
											<h2>{lngId.includes("fr") ? u.group.label_fr : u.group.label_en}</h2>
											<ul>
												{u.usages.map((x) => (
													<li key={x.id}>{lngId.includes("fr") ? x.text_fr : x.text_en}</li>
												))}
											</ul>
										</li>
									))}
								</ul>
							</div>
						</Accordion>
					</div>
				)}
				{details && details.length > 0 && (
					<div className={classes.Container}>
						<Accordion
							header={t(keys.PRODUCTS.OVERVIEW.DETAILS)}
							headerClass={classes.accordionContainer}
							iconClass={classes.iconClass}
						>
							<div className={classes.content}>
								{details.map((d) => (
									<div key={d.group.id} className={classes.detailElement}>
										<h2>{lngId.includes("fr") ? d.group.label_fr : d.group.label_en}</h2>
										<div className={classes.array}>
											{d.details.map((x) => (
												<div key={x.id} className={classes.row}>
													<p className={classes.col}>
														{lngId.includes("fr") ? x.key_fr : x.key_en}
													</p>
													{lngId.includes("fr")
														? x.value_fr
														: x.value_en && (
																<p className={classes.col}>
																	<HTMLString
																		html={
																			lngId.includes("fr")
																				? x.value_fr
																				: x.value_en
																		}
																	/>
																</p>
														  )}
												</div>
											))}
										</div>
									</div>
								))}
							</div>
						</Accordion>
					</div>
				)}
				{applications && applications.length > 0 && (
					<div className={classes.Container}>
						<Accordion
							header={t(keys.PRODUCTS.OVERVIEW.APPLICATION)}
							headerClass={classes.accordionContainer}
							iconClass={classes.iconClass}
						>
							<div className={classes.content}>
								<ul className={classes.applicationElement}>
									{applications.map((a) => (
										<div className={classes.container}>
											<h2>{lngId.includes("fr") ? a.title_fr : a.title_en}</h2>
											{lngId.includes("fr")
												? a.description_fr
												: a.description_en && (
														<div>
															<HTMLString
																html={
																	lngId.includes("fr")
																		? a.description_fr
																		: a.description_en
																}
															/>
														</div>
												  )}
										</div>
									))}
								</ul>
							</div>
						</Accordion>
					</div>
				)}
				{itemTechnicalSelectorGrouped && itemTechnicalSelectorGrouped.length > 0 && (
					<div className={classes.Container}>
						<Accordion
							header={t(keys.PRODUCTS.OVERVIEW.RELATED_DOC)}
							headerClass={classes.accordionContainer}
							iconClass={classes.iconClass}
						>
							{itemTechnicalSelectorGrouped.map((g) => (
								<div className={classes.documentsContent}>
									{g.documents
										?.filter((doc) => doc.language && doc.language.includes(lngId.substring(0, 2)))
										.map((doc) => (
											<div
												className={classes.documentsButton}
												key={doc.id}
												onClick={() => window.open(doc.url, "_blank")}
											>
												<Icon
													className={classes.documentsIconBlack}
													icon="icomoon-free:file-pdf"
												/>
												<p>{doc.title.substring(0, 65)}</p>
												<Icon
													className={classes.iconDownload}
													icon="iconoir:download-circled-outline"
												/>
											</div>
										))}
								</div>
							))}
						</Accordion>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default TechnicalOverview;
