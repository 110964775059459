import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import HomePage from "./Pages/HomePage";
import SigninPage from "./Pages/SigninPage";
import SignupPage from "./Pages/SignupPage";
import Term_PrivacyPage from "./Pages/Term_PrivacyPage";
import ProductsPage from "./Pages/ProductsPage";
import ProductOverviewPage from "./Pages/ProductOverviewPage";
import VariantPage from "./Pages/VariantPage";
import CartPage from "./Pages/CartPage";
import CheckoutPage from "./Pages/CheckoutPage";
import AccountPage from "./Pages/AccountPage";
import AddressPage from "./Pages/AddressPage";
import OrdersPage from "./Pages/OrdersPage";
import WalletPage from "./Pages/WalletPage";
import ProfilePage from "./Pages/ProfilePage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import EmailConfirmationPage from "./Pages/EmailConfirmationPage";
import NewsletterFormPage from "./Pages/NewsletterFormPage";
import InConstructionPage from "./Pages/InConstructionPage";
import ManufacturingPage from "./Pages/ManufacturingPage";
import ManufacturingSubSegmentPage from "./Pages/ManufacturingSubSegmentPage";
import ConstructionPage from "./Pages/ConstructionPage";
import ContactPage from "./Pages/ContactPage";
import ADRobotPage from "./Pages/ADRobotPage";
import DTechPage from "./Pages/DTechPage";
import ArchitectPage from "./Pages/ArchitectPage";
import WebinarPage from "./Pages/WebinarPage";
import VisitPage from "./Pages/VisitPage";
import LibraryPage from "./Pages/LibraryPage";
import SealantCalculatorPage from "./Pages/SealantCalculatorPage";
import SearchPage from "./Pages/SearchPage";
import EventsPage from "./Pages/EventsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import JamesHardiePage from "./Pages/JamesHardiePage";
import NichihaPage from "./Pages/NichihaPage";
import CeracladPage from "./Pages/CeracladPage";
import FindAStorePage from "./Pages/FindAStorePage";
import FAQPage from "./Pages/FAQPage";

const Routing = () => {
	return (
		<Routes>
			<Route path="/" exact element={<HomePage />} />
			<Route path="/sign-in/" exact element={<SigninPage />} />
			<Route path="/sign-up/" exact element={<SignupPage />} />
			<Route path="/term_privacy/" exact element={<Term_PrivacyPage />} />
			<Route path="/adstore/" exact element={<ProductsPage />} />
			<Route path="/adstore/overview/:itemid/" exact element={<ProductOverviewPage />} />
			<Route path="/adstore/overview/:itemid/:brandid/" exact element={<ProductOverviewPage />} />
			<Route
				path="/adstore/overview/:itemid/:brandid/:specificationid/"
				exact
				element={<ProductOverviewPage />}
			/>
			<Route
				path="/adstore/overview/:itemid/:brandid/:specificationid/:formatid/"
				exact
				element={<ProductOverviewPage />}
			/>
			<Route path="/adstore/variants/:itemid/:brandid/" exact element={<VariantPage />} />
			<Route path="/adstore/variants/:itemid/:brandid/:specificationid/" exact element={<VariantPage />} />
			<Route
				path="/adstore/variants/:itemid/:brandid/:specificationid/:formatid/"
				exact
				element={<VariantPage />}
			/>
			<Route path="/cart/" exact element={<CartPage />} />
			<Route path="/checkout/" exact element={<CheckoutPage />} />
			<Route path="/account/" exact element={<AccountPage />} />
			<Route path="/addresses/" exact element={<AddressPage />} />
			<Route path="/orders/" exact element={<OrdersPage />} />
			<Route path="/wallet/" exact element={<WalletPage />} />
			<Route path="/profile/" exact element={<ProfilePage />} />
			<Route path="/sign-in/forgot-password/" exact element={<ForgotPasswordPage />} />
			<Route path="/sign-in/password-reset-confirm/:uid/:token/" exact element={<ResetPasswordPage />} />
			<Route path="/sign-in/account-confirm-email/:token/" exact element={<EmailConfirmationPage />} />
			<Route path="/newsletter/" exact element={<NewsletterFormPage />} />
			<Route path="/in-construction/" exact element={<InConstructionPage />} />
			<Route path="/manufacturing/" exact element={<ManufacturingPage />} />
			<Route path="/manufacturing/:subSegemtId/" exact element={<ManufacturingSubSegmentPage />} />
			<Route path="/construction/" exact element={<ConstructionPage />} />
			<Route path="/contact-us/" exact element={<ContactPage />} />
			<Route path="/contact-us/confirmation/" exact element={<ContactPage />} />
			<Route path="/find-a-store/" exact element={<FindAStorePage />} />
			<Route path="/adrobot/" exact element={<ADRobotPage />} />
			<Route path="/dispensing-technologie/" exact element={<DTechPage />} />
			<Route path="/architect/" exact element={<ArchitectPage />} />
			<Route path="/architect/webinar/" exact element={<WebinarPage />} />
			<Route path="/architect/visit/" exact element={<VisitPage />} />
			<Route path="/architect/library/" exact element={<LibraryPage />} />
			<Route path="/architect/events/" exact element={<EventsPage />} />
			<Route path="/tools/sealant-calculator/" exact element={<SealantCalculatorPage />} />
			<Route path="/jameshardie/" exact element={<JamesHardiePage />} />
			<Route path="/nichiha/" exact element={<NichihaPage />} />
			<Route path="/ceraclad/" exact element={<CeracladPage />} />
			<Route path="/search/" exact element={<SearchPage />} />
			<Route path="/aboutus/" exact element={<AboutUsPage />} />
			<Route path="/faq/" exact element={<FAQPage />} />
			<Route path="/header/" exact element={<Navbar />} />
			<Route path="/footer/" exact element={<Footer />} />
			<Route path="*" render={() => <Navigate replace to="/" />} />
		</Routes>
	);
};
export default Routing;
