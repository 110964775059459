import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setSearch } from "../store/productQuery";

export const useSearch = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const searchChangeHandler = (event) => {
		dispatch(setSearch(event.target.value));

		if (event.target.value !== "") {
			queryParams.set("search", event.target.value);
		} else {
			queryParams.delete("search");
		}

		navigate(
			{
				pathname: location.pathname,
				search: queryParams.toString(),
			},
			{ replace: true }
		);
	};

	const enterSubmitHandler = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			navigate({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		}
	};

	const searchSubmitHandler = (event) => {
		event.preventDefault();
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const isMatchInTarget = (target, reference) => {
		const wordsInReference = reference.split(/\s+/);

		return wordsInReference.every((term) => target.includes(term));
	};

	return { searchChangeHandler, enterSubmitHandler, searchSubmitHandler, isMatchInTarget };
};
