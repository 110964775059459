import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookie from "js-cookie";
import ReactGA from "react-ga";

import PrivacyModal from "./components/Privacy/PrivacyModal";
import PrivacySideCart from "./components/Privacy/PrivacySideCart";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Routing from "./Routing";

import {
	accessExpSelector,
	isAdvertisingEnabled,
	isLoadingSelector,
	isLoggedInSelector,
	isLoggedOutSelector,
	isSavedCookie,
	languageSelector,
	refresh,
	saveCookies,
	setCookie,
	setCurrency,
} from "./store/auth";
import { clear, getOrder } from "./store/cart";
import { getMediaBase } from "./store/mediaBase";
import { getGoogleMapKey } from "./store/offices";
import { clearAll } from "./store/productQuery";

import { constants } from "./locales/constant";
import "./styles/global.scss";

let logoutTimer;

const calculateRemainingTime = (access_token_expiration) => {
	const currentTime = new Date().getTime();
	const refreshExpTime = new Date(access_token_expiration).getTime();
	return refreshExpTime - currentTime;
};

ReactGA.initialize(constants.TRACKING_ID);

function App() {
	const dispatch = useDispatch();

	const { i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const lngParm = queryParams.get("lng");

	const isAuthLoading = useSelector(isLoadingSelector);
	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoggedOut = useSelector(isLoggedOutSelector);
	const accessExp = useSelector(accessExpSelector);
	const language = useSelector(languageSelector);
	const savedCookie = useSelector(isSavedCookie);
	const advertisingEnabled = useSelector(isAdvertisingEnabled);

	const [showSideCart, setShowSideCart] = useState(false);

	const handleManageCookiesClick = () => {
		setShowSideCart(true);
	};

	const handleCloseSideCart = () => {
		setShowSideCart(false);
	};

	useEffect(() => {
		if (!location.pathname.includes("adstore")) {
			dispatch(clearAll());
		}
	}, [location?.pathname]);

	useEffect(() => {
		if (savedCookie === false) {
			const cookies = cookie.get("cookiesPolicy");
			if (cookies) {
				let cookiesJson = JSON.parse(cookies);
				if (cookiesJson.savedCookie === true && isAuthLoading === false) {
					if (isLoggedIn === true) {
						dispatch(setCookie(cookiesJson));

						dispatch(
							saveCookies({
								Advertising: cookiesJson.Advertising,
								Functional: cookiesJson.Functional,
								Analytical: cookiesJson.Analytical,
								savedCookie: cookiesJson.savedCookie,
							})
						);
					}
				}
			}
		}
	}, [isAuthLoading, isLoggedIn, savedCookie]);

	useEffect(() => {
		if (advertisingEnabled) {
			var s = document.createElement("script");
			s.type = "text/javascript";
			s.src = ("https:" === document.location.protocol ? "https://pi" : "http://cdn") + ".pardot.com/pd.js";

			var c = document.getElementsByTagName("script")[0];
			c.parentNode.insertBefore(s, c);
		}
	}, [window.location.href, advertisingEnabled]);

	useEffect(() => {
		dispatch(getMediaBase());
		dispatch(getGoogleMapKey());
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if (isLoggedIn === true) {
			dispatch(getOrder());
		} else if (isLoggedIn === false) {
			dispatch(clear());
		}
	}, [isLoggedIn]);

	const refreshHandler = useCallback(() => {
		if (!isAuthLoading) {
			dispatch(refresh());
		}
	}, [isAuthLoading]);

	useEffect(() => {
		if (isLoggedIn || !isLoggedOut) {
			logoutTimer = setTimeout(refreshHandler, calculateRemainingTime(accessExp) - 5000);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [refreshHandler, isLoggedIn, isLoggedOut, accessExp]);

	useEffect(() => {
		let localize = cookie.get("localise");
		if (localize !== undefined) {
			localize = JSON.parse(localize);
			if (localize && localize.lngId) i18n.changeLanguage(localize.lngId);
		} else if (navigator?.language) {
			i18n.changeLanguage(navigator.language);
		}
	}, []);

	useEffect(() => {
		if (lngParm && lngParm !== lngId) {
			i18n.changeLanguage(lngParm);
		}
		if (lngParm && lngParm.includes("fr")) {
			window.olark.configure("system.localization", "fr-FR");
		} else {
			window.olark.configure("system.localization", "en-US");
		}
		window.olark?.identify("4202-221-10-2740");
	}, []);

	useEffect(() => {
		if (lngId && lngId.includes("fr")) {
			window.olark("api.box.setLocale", "fr-FR");
		} else {
			window.olark("api.box.setLocale", "en-US");
		}
	}, [lngId]);

	useEffect(() => {
		if (language && language !== lngId) {
			i18n.changeLanguage(language);
		}
	}, [language]);

	useEffect(() => {
		if (lngId.includes("en") || lngId.includes("fr")) {
			const currency = lngId.includes("ca") ? "CAD" : "USD";
			dispatch(setCurrency(currency));

			queryParams.set("lng", lngId);
			navigate({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
			cookie.set("localise", { lngId, currency }, { sameSite: "lax", expires: 9999 });
		} else {
			i18n.changeLanguage("en-ca");
			const currency = lngId.includes("ca") ? "CAD" : "USD";
			dispatch(setCurrency(currency));

			queryParams.set("lng", lngId);
			navigate({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
			cookie.set("localise", { lngId, currency }, { sameSite: "lax", expires: 9999 });
		}
	}, [lngId]);

	return (
		<Fragment>
			{!location.pathname.includes("sign") &&
				!location.pathname.includes("header") &&
				!location.pathname.includes("footer") && <Navbar />}
			{lngId && <Routing />}
			{!showSideCart && isAuthLoading === false && savedCookie === false && (
				<PrivacyModal onManageCookiesClick={handleManageCookiesClick} />
			)}
			{
				<PrivacySideCart
					showSideCart={showSideCart && isAuthLoading === false && savedCookie === false}
					onClose={handleCloseSideCart}
				/>
			}
			{!location.pathname.includes("in-construction") &&
				!location.pathname.includes("sign") &&
				!location.pathname.includes("header") &&
				!location.pathname.includes("footer") &&
				!location.pathname.includes("account-confirm-email") && (
					<footer>
						<Footer />
					</footer>
				)}
		</Fragment>
	);
}

export default App;
